<template>
  <TTView>
    <VRow>
      <VCol>
        <h1>Контент-провайдер</h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <h2>{{ $t('content-provider.add_title') }}</h2>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <ContentProviderForm
          ref="form"
          :submit-button-text="$t('content-provider.create')"
          @submit="submit"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <TTDataTable
          :headers="headers"
          :items="providers.value"
          :loading="providers.loading"
          disable-pagination
          disable-sort
          class="provider-table"
          :item-class="providerStatusClass"
        >
          <template #item.id="{ item }">
            <span class="word-break-all">
              {{ item.id }}
            </span>
          </template>

          <template #item.title="{ item }">
            <span class="word-break-all">
              {{ item.title }}
            </span>
          </template>

          <template #item.siteLink="{ item }">
            <a
              class="word-break-all"
              :href="item.siteLink"
              :title="item.siteLink"
            >
              {{ item.siteLink }}
            </a>
          </template>

          <template #item.logo="{ item }">
            <a
              :href="providerLogoUrl(item)"
              :title="providerLogoUrl(item)"
            >
              <VImg
                width="100px"
                height="auto"
                :src="providerLogoUrl(item)"
              />
            </a>
          </template>

          <template #item.description="{ item }">
            <span class="word-break-all">
              {{ item.description }}
            </span>
          </template>

          <template #item.status="{ item }">
            <span class="word-break-all">
              {{ item.status }}
            </span>
          </template>

          <template #item.actions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="warning"
                  v-bind="attrs"
                  v-on="on"
                  @click="toEditPage(item.id)"
                >
                  <VIcon small>
                    $edit
                  </VIcon>
                </VBtn>
              </template>
              <span>Редактировать</span>
            </VTooltip>
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="providerArchiveDialogOpen(item)"
                >
                  <VIcon small>
                    $archive
                  </VIcon>
                </VBtn>
              </template>
              <span>Архивировать</span>
            </VTooltip>

            <VTooltip
              v-if="item.status === ContentProviderStatus.BLOCKED || item.status === ContentProviderStatus.DRAFT"
              left
            >
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                  @click="activateProvider(item)"
                >
                  <VIcon small>
                    fal fa-check
                  </VIcon>
                </VBtn>
              </template>
              <span>Активировать</span>
            </VTooltip>
            <VTooltip
              v-if="item.status === ContentProviderStatus.ACTIVE"
              left
            >
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="danger"
                  v-bind="attrs"
                  v-on="on"
                  @click="providerBlockDialogOpen(item)"
                >
                  <VIcon small>
                    fal fa-ban
                  </VIcon>
                </VBtn>
              </template>
              <span>Заблокировать</span>
            </VTooltip>
          </template>
        </TTDataTable>
        <VPagination
          v-if="providersPageTotal > 0"
          v-model="providers.page"
          total-visible="10"
          :length="providersPageTotal"
        />
      </VCol>
    </VRow>

    <VDialog
      v-model="providerArchiveDialog"
      max-width="500px"
      @click:outside="providerArchiveDialogClose"
    >
      <VCard>
        <VCardTitle>
          Архивировать контент-провайдера
        </VCardTitle>
        <VCardText>
          Этот контент-провайдер будет архивирован! <br>
          Действительно хотите архивировать контент-провайдера?
        </VCardText>
        <VCardActions>
          <VSpacer />
          <VBtn
            text
            @click="providerArchiveDialogClose"
          >
            Нет
          </VBtn>
          <VBtn
            color="red darken-1"
            text
            @click="archiveProvider({ id : providerArchiveDialogId })"
          >
            Да
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
    <VDialog
      v-model="providerBlockDialog"
      max-width="500px"
      @click:outside="providerBlockDialogClose"
    >
      <VCard>
        <VCardTitle>
          Заблокировать контент-провайдера
        </VCardTitle>
        <VCardText>
          Этот контент-провайдер будет заблокирован! <br>
          Действительно хотите заблокировать контент-провайдера?
        </VCardText>
        <VCardActions>
          <VSpacer />
          <VBtn
            text
            @click="providerBlockDialogClose"
          >
            Нет
          </VBtn>
          <VBtn
            color="red darken-1"
            text
            @click="blockProvider({ id : providerBlockDialogId })"
          >
            Да
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </TTView>
</template>

<script>
import { ContentProviderStatus } from '@front.backoffice/api/src/services/catalogue/constants';
import getImageUrl from '@front.backoffice/common/src/getGoghaImageUrl';
import ContentProviderForm from '../../components/content-provider/ContentProviderForm.vue';

export default {
  name: 'ContentProvider',
  components: {
    ContentProviderForm,
  },
  inject: ['Names'],
  data() {
    return {
      providers: {
        value: [],
        page: 1,
        limit: 20,
        total: 0,
        loading: false,
      },
      headers: [
        { text: 'Id', value: 'id', width: '20%' },
        { text: 'Название', value: 'title', width: '20%' },
        { text: 'Ссылка', value: 'siteLink', width: '10%' },
        { text: 'Логотип', value: 'logo', width: '10%' },
        { text: 'Описание', value: 'description', width: '20%' },
        { text: 'Статус', value: 'status', width: '10%' },
        {
          text: 'Действия', value: 'actions', width: '10%', cellClass: 'actions',
        },
      ],
      providerArchiveDialogId: null,
      providerArchiveDialog: false,
      providerBlockDialogId: null,
      providerBlockDialog: false,
      ContentProviderStatus,
    };
  },
  computed: {
    providersPageTotal() {
      return Math.ceil(this.providers.total / this.providers.limit);
    },
  },
  watch: {
    'providers.page': function (newValue) {
      this.fetchProviders(newValue);
    },
  },
  created() {
    this.fetchProviders(1);
  },
  methods: {
    async fetchProviders(page = this.providers.page, limit = this.providers.limit) {
      try {
        this.providers.loading = true;
        const { providers = [], pagination = {} } = await this.$di.api.Catalogue.providerListGet({
          page,
          limit,
        });
        this.providers.value = providers;
        this.providers.page = page;
        this.providers.total = pagination.total;
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.providers.loading = false;
      }
    },
    async createProvider(provider) {
      try {
        this.loading = true;
        const { id } = await this.$di.api.Catalogue.providerCreate({
          provider,
        });
        await this.uploadProviderLogo({ id }, provider.logo);
        await this.activateProvider({ id });
        this.$di.notify.snackSuccess('Провайдер создан');
        await this.fetchProviders(this.providers.page);
        return true;
      } catch (error) {
        this.$di.notify.errorHandler(error);
        return false;
      } finally {
        this.loading = false;
      }
    },
    async archiveProvider({ id }) {
      try {
        this.loading = true;
        await this.$di.api.Catalogue.providerArchive({
          id,
        });
        this.$di.notify.snackSuccess('Провайдер архивирован');
        this.providerArchiveDialogClose();
        await this.fetchProviders(this.providers.page);
        return true;
      } catch (error) {
        this.$di.notify.errorHandler(error);
        return false;
      } finally {
        this.loading = false;
      }
    },
    async activateProvider({ id }) {
      try {
        this.loading = true;
        await this.$di.api.Catalogue.providerActivate({
          id,
        });
        this.$di.notify.snackSuccess('Провайдер активирован');
        await this.fetchProviders(this.providers.page);
        return true;
      } catch (error) {
        this.$di.notify.errorHandler(error);
        return false;
      } finally {
        this.loading = false;
      }
    },
    async blockProvider({ id }) {
      try {
        this.loading = true;
        await this.$di.api.Catalogue.providerBlock({
          id,
        });
        this.$di.notify.snackSuccess('Провайдер заблокирован');
        this.providerBlockDialogClose();
        await this.fetchProviders(this.providers.page);
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    async uploadProviderLogo(provider, image) {
      try {
        this.loading = true;

        const data = new FormData();
        data.append('image', image);

        return this.$di.api.Gogha.UploadImage('provider', provider.id, 'icon', data);
      } catch (error) {
        this.$di.notify.errorHandler(error);
        return null;
      } finally {
        this.loading = false;
      }
    },
    async submit(isValid, provider) {
      if (isValid) {
        await this.createProvider(provider);
        this.$refs.form.reset();
      }
    },
    async toEditPage(id) {
      return this.$router.push({
        name: this.Names.R_LEARNING_CONTENT_PROVIDER_EDIT,
        params: {
          id,
        },
      });
    },
    providerArchiveDialogOpen({ id }) {
      this.providerArchiveDialog = true;
      this.providerArchiveDialogId = id;
    },
    providerArchiveDialogClose() {
      this.providerArchiveDialog = false;
      this.providerArchiveDialogId = null;
    },
    providerBlockDialogOpen({ id }) {
      this.providerBlockDialog = true;
      this.providerBlockDialogId = id;
    },
    providerBlockDialogClose() {
      this.providerBlockDialog = false;
      this.providerBlockDialogId = null;
    },
    providerStatusClass({ status }) {
      const classes = {
        [ContentProviderStatus.ACTIVE]: 'active',
        [ContentProviderStatus.BLOCKED]: 'blocked',
      };
      return classes[status];
    },
    providerLogoUrl(provider) {
      return getImageUrl({
        uuid: provider.id,
        type: 'provider',
        name: 'icon',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.provider-table {
  tr.blocked {
    td:not(.actions) {
      opacity: 0.3;
    }
  }
}

.word-break-all {
  word-break: break-all;
}
</style>
